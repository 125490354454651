import Vue from 'vue'
import Http from '@/plugins/http'
import router from '@/router'
import tabController from '@/tabController'
import recentsController from '@/recentsController'
import sidePanelController from '@/sidePanelController'

export default new Vue({
  Http,
  router,
  methods: {
    async downloadFile (file, params) {
      const token = (await this.$http().get('/tempToken')).data.token
      const path = !params ? `file/${file.id}` : 'importAttachment'
      const url = new URL(`${this.$http().baseURL()}/${path}`, window.location)
      const urlParams = new URLSearchParams(url.search)
      urlParams.append('token', token)
      if (params) {
        urlParams.append('filename', file.name)
        urlParams.append('dataSourceFolder', params.dataSourceFolder)
      }
      window.open(`${url.href}?${urlParams.toString()}`, '_blank')
    },
    async navigateTo (id, type, params, isReplace) {
      if (type === 'runs') {
        const r = await this.$http().get('/core/run/' + id)
        const url = r.data.url
        if (url) {
          return this.$router.push(r.data.url).catch(() => { })
        } else {
          return
        }
      }

      if (type === 'topics' || type === 'columns' || type === 'column-sets' || type === 'sort-sets') {
        return sidePanelController.displayDocument(id, type, params)
      }

      const currentAccountId = this.$route.name === 'account' ? this.$route.params.id : null

      try {
        const r = await this.$http().post('core/v6/nav', { doc: { id, type }, currentAccountId })
        const accountId = r.data.accountId
        if (type === 'user-accounts') {
          type = 'account-contacts'
          id = id[0]
        }
        if (type === 'dispute-invoices') {
          type = 'disputes'
          id = id[0]
        }
        if (type === 'promise-invoices') {
          type = 'promises'
          id = id[0]
        }
        if (type === 'unapplied-cashes' || type === 'work-in-progress-items') {
          if (!currentAccountId) {
            return recentsController.goToStaticTab({
              documentType: 'accounts',
              documentId: accountId
            }, 'infos')
          } else {
            return
          }
        }

        if (type === 'work-items') {
          return recentsController.goToStaticTab({
            documentType: 'accounts',
            documentId: accountId
          }, 'to-do')
        }

        const scope = accountId ? { documentType: 'accounts', documentId: accountId } : {}

        const tabDocument = tabController.getTabFromDocumentType(type)

        if (tabDocument) {
          if (accountId) {
            const tab = tabController.create(tabDocument, { documentId: id })
            recentsController.addTab(tab, scope, isReplace)
          } else {
            recentsController.goToStaticTab({ documentId: id, documentType: type }, type)
          }
        } else {
          if (isReplace === true) {
            this.$router.replace('/' + type + '/' + id).catch(() => { })
          } else {
            this.$router.push('/' + type + '/' + id).catch(() => { })
          }
        }
      } catch (e) {
        return e
      }
    }
  }
})
