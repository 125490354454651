import i18n from './plugins/i18n'
import recentsController from '@/recentsController'
import tabController from '@/tabController'
import Vue from 'vue'
import Debug from '@/debugger'
const debug = Debug('cot:side-panel:controller')

export default new Vue({
  i18n,
  data () {
    return {
      showNewColumnWarning: false,
      documentList: [],
      isSidePanelHidden: true
    }
  },
  computed: {
    displayedDocument () {
      return this.documentList[0]
    }
  },
  methods: {
    /**
     * Add a document to the list
     * @param {string} id A documentId
     * @param {string} type A DocumentType document
     * @param {Object} [params] An object containing params for the document in side panel, optionnal
     */
    addDocument (id, type, params) {
      debug('Document added - type:%s id:%s params:%j', type, id, params)
      this.documentList.unshift(Object.assign({}, { id, type, key: id ?? '' + type, params }))
    },
    closeDisplayed () {
      this.documentList.pop()
    },
    /**
     * Display the given document on top of the list
     * @param {string} id A documentId
     * @param {string} type A DocumentType document
     * @param {Object} [params] An object containing params for the document in side panel, optionnal
     */
    displayDocument (id, type, params, skipWarning) {
      debug('Displaying document - type:%s id:%s params:%j', type, id, params)
      const index = this.documentList.findIndex(d => d.id === id && d.type === type)

      if (!id && index !== -1 && !skipWarning) {
        this.showNewColumnWarning = true
        if (index > 0) {
          const doc = this.documentList.splice(index, 1)[0]
          this.addDocument(doc.id, doc.type, doc.params)
        }
        this.openSidePanel()
        return
      }

      // If we don't have the document in the list add it to the list
      if (index === -1) {
        if (this.documentList.length >= 10) {
          this.documentList.pop()
        }
        this.addDocument(id, type, params)
      }

      // If we have the document in the list and it's not the first one, put it on the top of the list
      if (index > 0) {
        const doc = this.documentList.splice(index, 1)[0]
        this.addDocument(doc.id, doc.type, doc.params)
      }

      this.openSidePanel()
    },
    hideSidePanel () {
      this.isSidePanelHidden = true
    },
    openSidePanel () {
      this.isSidePanelHidden = false
    },
    async openInFull () {
      this.hideSidePanel()
      const r = await this.$http().post('core/v6/nav', { doc: { id: this.displayedDocument.id, type: this.displayedDocument.type }, currentAccountId: null })
      const accountId = r.data.accountId
      if (accountId) {
        const scope = { documentType: 'accounts', documentId: accountId }
        const tab = tabController.create(tabController.topicsTab(), { documentId: this.displayedDocument.id })
        recentsController.addTab(tab, scope)
      } else {
        recentsController.goToStaticTab({
          documentType: this.displayedDocument.type,
          documentId: this.displayedDocument.id
        }, this.displayedDocument.type)
      }
    },
    removeCurrentDocument () {
      debug('Document removed - type:%s id:%s', this.displayedDocument.type, this.displayedDocument.id)
      this.documentList = this.documentList.slice(1)
    },
    toggle () {
      this.isSidePanelHidden = !this.isSidePanelHidden
    }
  }
})
